import { themeObj } from './main-menu-theme';

require('./clawson-vue');
require('./utils');

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
const checkIsIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

$(document).ready(function() {
  var windowsize = $(window).width();

  if (windowsize <= 992) {
    $('#menu-btn').prop('checked');

    $('#menu-btn').change(function() {
      if ($(this).prop("checked") == true) {
        $('.clawson-header').addClass('menuOpened');
      }
      else if ($(this).prop("checked") == false) {
        $('.clawson-header').removeClass('menuOpened');
      }
    });
  }
  
  if (checkIsIOS()) return;

  //*
  // ? This is the code that changes the theme on click of the clock icon
  // ? It is currently commented out because it is not needed
  /*
  let muteThemeObj = themeObj;
  $('.header-clock').on( "click", () => {
    muteThemeObj = changeTheme( muteThemeObj.name );
    $('.logo-background').css( { backgroundImage : muteThemeObj.logoBackground , backgroundPosition : muteThemeObj.logoBackgroundPosition } );
    $('.clawson-header').removeClass( ['black',  'white'] ).addClass( muteThemeObj.color );
    $('.clawson-header').css( { backgroundImage : muteThemeObj.background , backgroundPosition : muteThemeObj.backgroundPosition } );
    $('.header-map-maker').removeClass( ['black',  'white'] ).addClass( muteThemeObj.color );
    $('.header-phone').removeClass( ['black',  'white'] ).addClass( muteThemeObj.color );
    $('.header-clock').removeClass( ['black',  'white'] ).addClass( muteThemeObj.color );
  } );
  */

  if (!checkIsIOS()) {
    $('.logo-background').css( { backgroundImage : themeObj.logoBackground , backgroundSize : 'cover' , backgroundPosition : themeObj.logoBackgroundPosition } );
    $('.clawson-header').addClass( themeObj.color );
    $('.clawson-header').css( { backgroundImage : themeObj.background , backgroundPosition : themeObj.backgroundPosition } );
    $('.header-map-maker').addClass( themeObj.color );
    $('.header-phone').addClass( themeObj.color );
    $('.header-clock').addClass( themeObj.color );
  }

  // temp fix for safari/iOS carchat issue
  // disabled for now 6/21/24
  // $(document).on("click", (e) => {
  //   // check if click in bottom left corner
  //   if (e.originalEvent) {
  //     let bub = $('[class*="round-chat"');
  //     if (bub) {
  //       let isBot = e.originalEvent.y >= bub.offset().top;
  //       let isTop = e.originalEvent.x <= 90;
  //       // force a click on the carchat icon
  //       if (isBot && isTop) {
  //         bub.click();
  //       }
  //     }
  //   }
  // });

});

$(window).scroll(function() {
  if ($(window).scrollTop() > 0) {
    $(".clawson-header").addClass("scrolling");
  } else if ($(window).scrollTop() == 0) {
    $(".clawson-header").removeClass("scrolling");
  }
});

$(function () {
  const addMaximumScaleToMetaViewport = () => {
    const el = document.querySelector('meta[name=viewport]');

    if (el !== null) {
      let content = el.getAttribute('content');
      let re = /maximum\-scale=[0-9\.]+/g;

      if (re.test(content)) {
        content = content.replace(re, 'maximum-scale=1.0');
      } else {
	      content = [content, 'maximum-scale=1, user-scalable=0'].join(', ')
      }

      el.setAttribute('content', content);
    }
  };

  const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

  if (checkIsIOS()) {
    disableIosTextFieldZoom();
  }
});
